<template>
  <ImageContainer
    class="img-caption"
    :style="`--caption-pb: ${captionPB}px; --radius: ${radius}`"
  >
    <div class="caption">
      <slot />
    </div>
  </ImageContainer>
</template>

<script>
import ImageContainer from "@/components/ImageContainer.vue";

export default {
  props: {
    captionPB: {
      type: Number,
      default: 20,
    },
    radius: {
      type: String,
      default: "1.25em",
    },
  },
  components: {
    ImageContainer,
  },
};
</script>

<style></style>
