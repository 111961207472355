<template>
  <div class="card section-card">
    <div class="card-body p-0">
      <ImageContainer class="img-section-card" :size="size" v-bind="$attrs">
        <div class="caption">
          <slot />
        </div>
      </ImageContainer>
    </div>
  </div>
</template>

<script>
import ImageContainer from "@/components/ImageContainer.vue";

export default {
  props: {
    size: {
      type: Number,
      default: 600,
    },
  },
  components: {
    ImageContainer,
  },
};
</script>

<style></style>
