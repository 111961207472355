<template>
  <vue-cookie-accept-decline
    :debug="false"
    :disableDecline="false"
    :showPostponeButton="false"
    elementId="myPanel1"
    position="bottom-left"
    ref="myPanel1"
    transitionName="slideFromBottom"
    type="floating"
  >
    <!-- Optional -->
    <template #message>
      Esta web usa cookies para asegurarte la mejor experiencia en nuestro
      sitio.
      <a href="/files/POLÍTICA DE COOKIES - NL College.pdf" target="_blank"
        >Saber más..</a
      >
    </template>

    <!-- Optional -->
    <template #declineContent>Rechazar</template>

    <!-- Optional -->
    <template #acceptContent>Aceptar</template>
  </vue-cookie-accept-decline>
</template>

<script>
import VueCookieAcceptDecline from "vue-cookie-accept-decline";

export default {
  components: {
    VueCookieAcceptDecline,
  },
};
</script>
